@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
html {
    font-family: 'Poppins', sans-serif;
  }

body {
  /* background-image: url('./assets/SHOP NOW (20).png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Remove or comment out this line */
/* background-image: url('/grid.svg'); */